<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-flex justify-space-between">
        <div class="page-title">{{ $route.meta.title }}</div>
        <v-btn
          color="primary"
          class="px-6 font-weight-bold"
          depressed
          small
          :to="{ name: 'orderCreate' }"
          :disabled="tableLoading"
          >新增</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col md="3" sm="6" cols="12">
        <form-control
          inputType="select"
          v-model="filterCourseMode"
          :options="courseModeList"
          label="課程類型"
          :disabled="tableLoading"
          :dense="true"
        />
      </v-col>
      <v-col md="3" sm="6" cols="12">
        <form-control
          :dense="true"
          inputType="dateRangePicker"
          v-model="filterOrderDates"
          label="訂單日期"
          :disabled="tableLoading"
        />
      </v-col>
      <v-col md="3" sm="6" cols="12">
        <form-control
          :dense="true"
          inputType="string"
          v-model="filterCourseCode"
          label="課程編號"
          :disabled="tableLoading"
        />
      </v-col>
      <v-col md="3" sm="6" cols="12">
        <form-control
          :dense="true"
          inputType="string"
          v-model="filterStudentPhoneEmail"
          label="學生電話及電郵"
          :disabled="tableLoading"
        />
      </v-col>
      <v-col md="3" sm="6" cols="12">
        <form-control
          :dense="true"
          inputType="string"
          v-model="filterInvoiceID"
          label="訂單編號"
          :disabled="tableLoading"
        />
      </v-col>
      <v-col md="6" sm="12" cols="12" class="d-flex align-center">
        <v-btn
          color="success"
          class="px-6 font-weight-bold mr-5"
          depressed
          small
          :disabled="tableLoading"
          @click="searchOrder"
          >搜尋</v-btn
        >
        <v-btn color="error" class="px-6 font-weight-bold" depressed small :disabled="tableLoading" @click="clearSearch"
          >清除</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <Datatable
          :table-headers="tableHeaders"
          :table-data="formData"
          :page="tablePage"
          :page-limit="tablePageLimit"
          :page-limit-options="tablePageLimitOptions"
          :item-total="formItemTotal"
          :is-loading="tableLoading"
          :disable-pagination="tableLoading"
          @options-update="onTableChange"
          :actionViewRouteLink="'orderDetail'"
        >
        </Datatable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Datatable from '@/components/Datatable.vue'
import FormControl from '@/components/form/FormControl.vue'

export default {
  name: 'Order',
  components: {
    Datatable,
    FormControl,
  },
  data() {
    return {
      tableLoading: false,
      tableHeaders: [
        // { text: '建立日期', value: 'create_date' },
        { text: '訂單編號', value: 'format_invoice_id' },
        { text: '課程類型', value: 'course_mode' },
        { text: '課程標題', value: 'course_title' },
        { text: '學生名稱', value: 'user_name' },
        { text: '電話', value: 'phone' },
        { text: '電郵', value: 'email' },
        { text: '付款方式', value: 'payment_method' },
        { text: '總金額($)', value: 'total' },
        { text: '已付金額($)', value: 'paid_amount' },
        {
          text: '',
          value: 'actionView',
          sortable: false,
          align: 'end',
        },
      ],
      tableLoading: false,
      tablePage: 1,
      tablePageLimit: 20,
      tablePageLimitOptions: [20, 50, 100],
      formItemTotal: 0,
      formData: [],

      filterCourseMode: '',
      filterOrderDates: [],
      filterCourseCode: '',
      filterStudentPhoneEmail: '',
      filterInvoiceID: '',
    }
  },
  computed: {
    courseModeList: function () {
      return this.$courseModeList
    },
  },
  methods: {
    async getOrderData() {
      if (!this.tableLoading) {
        this.tableLoading = true

        try {
          const payload = {
            limit: this.tablePageLimit,
            page: this.tablePage - 1,
            verify_token: this.getAdminToken(),
            get_user: true,
            get_updated_course: true,
            get_payment: true,
            calculate_total_paid: true,
          }
          if (this.$validate.DataValid(this.filterCourseCode)) {
            payload.filter_course_code = this.filterCourseCode
          }

          if (this.$validate.DataValid(this.filterCourseMode)) {
            payload.filter_course_mode = this.filterCourseMode
          }

          if (this.$validate.DataValid(this.filterOrderDates)) {
            if (this.filterOrderDates.length === 1) {
              payload.filter_create_start_date = this.filterOrderDates[0]
            } else if (this.filterOrderDates.length === 2) {
              payload.filter_create_start_date = this.filterOrderDates[0]
              payload.filter_create_end_date = this.filterOrderDates[1]
            }
          }

          if (this.$validate.DataValid(this.filterStudentPhoneEmail)) {
            payload.search_student = this.filterStudentPhoneEmail
          }

          if (this.$validate.DataValid(this.filterInvoiceID)) {
            payload.search_invoice_id = this.filterInvoiceID
          }

          const result = await this.$XHR.api('cms_get_order', payload)
          this.$func.log('-----Get Order List-----')
          this.$func.log(result)

          this.formItemTotal = result.total

          const temp = []

          for (let i = 0; i < result.data.length; i++) {
            const resultData = result.data[i]

            const data = {
              id: resultData.id,
              create_date: resultData.create_date,
              course_mode: resultData.course_data.course_mode,
              course_title: resultData.course_data.course_name,
              user_name: '-',
              phone: '-',
              email: '-',
              payment_method: '-',
              total: resultData.total,
              paid_amount: resultData.paid_amount,
            }

            if (this.$validate.DataValid(resultData.updated_course)) {
              data.course_mode = resultData.updated_course.course_mode
              data.course_title = resultData.updated_course.course_name
            }

            if (this.$validate.DataValid(resultData.user_data)) {
              data.user_name = this.$validate.DataValid(resultData.user_data.user_name)
                ? resultData.user_data.user_name
                : '-'
              data.phone = this.$validate.DataValid(resultData.user_data.phone) ? resultData.user_data.phone : '-'
              data.email = resultData.user_data.email
            }

            if (this.$validate.DataValid(resultData.payment_data)) {
              data.payment_method = resultData.payment_data.payment_method
              data.format_invoice_id = `#INV${resultData.id}`
            } else {
              data.payment_method = resultData.payment_method

              if (this.$validate.DataValid(resultData.payment_list[0].invoice_id)) {
                data.format_invoice_id = `#INV${resultData.payment_list[0].invoice_id}`
              } else {
                data.format_invoice_id = `#INV${resultData.id}`
              }
            }

            temp.push(data)
          }

          this.formData = temp
        } catch (error) {
          this.$func.log('-----Get Order List fail-----')
          this.$func.log(error)

          let msg = ''

          if (error.data === 'admin verification fail') {
            this.forceLogout()
          } else if (error.data === 'no permission') {
            msg = '沒有權限'
          }

          if (this.$validate.DataValid(msg)) {
            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: msg,
              type: 'error',
              refresh: false,
              redirect: '',
            })
          }

          this.formData = []
          this.formItemTotal = 0
        } finally {
          this.tableLoading = false
        }
      }
    },
    searchOrder() {
      if (
        this.$validate.DataValid(this.filterCourseCode) ||
        this.$validate.DataValid(this.filterCourseMode) ||
        this.$validate.DataValid(this.filterOrderDates) ||
        this.$validate.DataValid(this.filterStudentPhoneEmail) ||
        this.$validate.DataValid(this.filterInvoiceID)
      ) {
        this.tablePage = 1
        this.formData = []
        this.formItemTotal = 0
        this.getOrderData()
      }
    },
    clearSearch() {
      this.filterCourseCode = ''
      this.filterCourseMode = ''
      this.filterOrderDates = []
      this.filterStudentPhoneEmail = ''
      this.filterInvoiceID = ''
      this.tablePage = 1
      this.formItemTotal = 0
      this.formData = []

      this.getOrderData()
    },
    onTableChange(options) {
      if (options.itemsPerPage !== this.tablePageLimit) {
        this.tablePage = 1 // reset to first page if view options changed
      } else {
        this.tablePage = options.page
      }

      if (options.sortBy.length > 0) {
        this.tableSortKey = options.sortBy[0]
      }
      this.tableSortDesc = options.sortDesc.length > 0 ? options.sortDesc[0] : true

      this.tablePageLimit = options.itemsPerPage
      this.getOrderData()
    },
  },
  async created() {
    const check = await this.checkUserData()
    if (check) {
      this.getOrderData()
    }
  },
}
</script>
